.chessboard-spinner[hidden] {
  display: none;
}

.chessboard-spinner {
  vertical-align: middle;
  grid-template-columns: repeat(2, .5rem);
  display: inline-grid;
}

.chessboard-spinner span {
  height: .5rem;
  width: .5rem;
  animation: 1s infinite chessboard-flip;
}

.chessboard-spinner :nth-child(1) {
  background: #fff;
  animation-delay: 0s;
}

.chessboard-spinner :nth-child(2) {
  background: #222;
  animation-delay: .25s;
}

.chessboard-spinner :nth-child(3) {
  background: #222;
  animation-delay: .75s;
}

.chessboard-spinner :nth-child(4) {
  background: #fff;
  animation-delay: .5s;
}

@keyframes chessboard-flip {
  100% {
    transform: rotateY(180deg);
  }
}

.beacon {
  display: none;
}

.beacon.active {
  width: 2rem;
  height: 2rem;
  background: #ff962c;
  border-radius: 50%;
  margin-left: .5rem;
  animation: 1s ease-out infinite pulse;
  display: inline-block;
  position: absolute;
}

@keyframes pulse {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  100% {
    opacity: 0;
    transform: scale(2);
  }
}

/*# sourceMappingURL=index.0bfb8478.css.map */
