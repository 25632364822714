.chessboard-spinner[hidden] {
  display: none;
}

.chessboard-spinner {
  display: inline-grid;
  grid-template-columns: repeat(2, 0.5rem);
  vertical-align: middle;
}

.chessboard-spinner span {
  animation: chessboard-flip 1s infinite;
  height: 0.5rem;
  width: 0.5rem;
}

.chessboard-spinner :nth-child(1) {
  background: white;
  animation-delay: 0s;
}

.chessboard-spinner :nth-child(2) {
  background: #222;
  animation-delay: 0.25s;
}

.chessboard-spinner :nth-child(3) {
  background: #222;
  animation-delay: 0.75s;
}

.chessboard-spinner :nth-child(4) {
  background: white;
  animation-delay: 0.5s;
}

@keyframes chessboard-flip {
  100% {
    transform: rotateY(180deg);
  }
}
