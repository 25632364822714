.beacon {
  display: none;
}

.beacon.active {
  display: inline-block;
  position: absolute;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  margin-left: 0.5rem;
  animation: pulse 1s ease-out infinite;
  background: #ff962c;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}
